import { render, staticRenderFns } from "./agentStudentList.vue?vue&type=template&id=58d6dde4&scoped=true"
import script from "./agentStudentList.vue?vue&type=script&lang=js"
export * from "./agentStudentList.vue?vue&type=script&lang=js"
import style0 from "./agentStudentList.vue?vue&type=style&index=0&id=58d6dde4&prod&lang=less"
import style1 from "./agentStudentList.vue?vue&type=style&index=1&id=58d6dde4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58d6dde4",
  null
  
)

export default component.exports